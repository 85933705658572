import React from 'react';
import Helmet from 'react-helmet';
import { Section, Container } from '../components/common';
import { Navigation } from '../components/layout/index.js';
import DataLayer from '../components/layout/DataLayer';

const Index = () => (
  <>
    <Helmet>
      <title>404</title>
    </Helmet>
    <Navigation darkmenu />
    <Section lightBackground>
      <Container maxWidth="900px">
        <p>Hi</p>
      </Container>
    </Section>
    <DataLayer event="404" />
  </>
);

export default Index;
